export default [
  {
    target: "kzhik",
    achievement: "[探索自我]",
    description: "在学习中不断探索，深入了解自己热爱的领域，找到了心灵的归属",
  },
  {
    target: "kzhik",
    achievement: "[编织代码]",
    description:
      "谱写属于自己的数字故事，哪怕只是平凡的旋律，我依然觉得这样很美",
  },
  {
    target: "kzhik",
    achievement: "[方块世界]",
    description: "在Minecraft里探索, 创造属于自己的奇迹",
  },
]