<script setup>
import { onMounted, ref } from "vue";
import ViewCard from "../ViewCard.vue";
import contents from "./assets/CONTENTS.svg";
import cards from "./cards";
const ROOT = ref();

function handleAnimate() {
  var root = ROOT.value;
  var elements = root.querySelectorAll(".cardOuter");
  var i = 0;
  var max = elements.length;

  var interval = setInterval(() => {
    if (i >= max) {
      clearInterval(interval);
      return;
    }

    elements[i].setAttribute("data-animate", "true");
    i++;
  }, 200);
}

function checkAnimateIsFinish() {
  var root = ROOT.value;

  // 判断root的父元素是否拥有data-animate属性
  // 判断卡片是否被展开且动画播放完成
  if (root.parentElement.hasAttribute("data-animate")) {
    handleAnimate();
    return;
  }

  requestIdleCallback(checkAnimateIsFinish, { timeout: 350 });
}

function handleHidden() {
  var root = ROOT.value;
  var elements = root.querySelectorAll(".cardOuter");

  for (var i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("data-animate");
  }

  setTimeout(() => {
    checkAnimateIsFinish();
  }, 200);
}

onMounted(() => {
  checkAnimateIsFinish();
});
</script>

<template>
  <div ref="ROOT" class="root">
    <button
      title="关闭目录"
      @click="handleHidden"
      class="close"
      data-hidden="contents"
    >
      ×
    </button>
    <h1 class="title">目录</h1>
    <div class="content">
      <img class="contentText" :src="contents" alt="" />

      <div class="cards">
        <ViewCard
          v-for="elem in cards"
          :hidden="elem.hidden"
          :key="elem"
          :hover-color="elem.hoverColor"
          :vc-id="elem.vcId"
          :content-bg="elem.contentBg"
          :description="elem.description"
          :title="elem.title"
          :auto-close="elem.autoClose"
        >
          <template v-slot:icon>
            <i :class="`bi bi-${elem.icon}`"></i>
          </template>
          <template v-slot:content>
            <component :is="elem.content.name" v-bind="elem.content.attributes">
              {{ elem.content.value }}
            </component>
          </template>
        </ViewCard>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: var(--title-std-size);
  color: #444;
}

.root {
  padding: 16px;
  position: relative;
  max-width: 1250px;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 卡片可见部分添加动画效果 */
.root >>> .cardOuter {
  opacity: 0;
}

.root >>> .cardOuter[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.close {
  font-size: 1cm;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: dodgerblue;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
