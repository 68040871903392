<script setup>
import { defineProps, onMounted, ref } from "vue";
import AchievementItem from "./AchievementItem.vue";
import AboutMeText from "../assets/ABOUT_ME.svg";
import BilibiliIcon from "../assets/bilibili.svg";
import achievementsList from "./achievements";

defineProps(["vcId"]);

const root = ref();

function playAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  var index = 0;

  var interval = setInterval(() => {
    if (index >= elements.length) {
      clearInterval(interval);
      return;
    }

    elements[index].setAttribute("data-animate", "true");
    index++;
  }, 450);
}

function stopAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("data-animate");
  }
}

function initObserver() {
  var observer = new IntersectionObserver((elem) => {
    if (elem[0].isIntersecting) {
      playAnimate();
    } else {
      stopAnimate();
    }
  });

  observer.observe(root.value);
}

onMounted(() => {
  initObserver();
});
</script>

<template>
  <div ref="root" class="root">
    <div class="top">
      <h1 class="title">关于我</h1>
      <button class="close" :data-hidden="vcId">×</button>
    </div>
    <div class="bottom">
      <img :src="AboutMeText" alt="ABOUT ME" />
      <div class="content">
        <div class="section">
          <h1>Hi!</h1>
          <p>我是kzhik, 一位对技术、创意和生活抱有浓厚兴趣的学生</p>
        </div>
        <div class="section">
          <h1>成就</h1>
          <p>
            <AchievementItem
              :key="x"
              v-for="x in achievementsList"
              :target="x.target"
              :achievement="x.achievement"
              :description="x.description"
            />
          </p>
          <p>是不是感觉很熟悉? 对! 灵感正是来自Minecraft😀</p>
          <p>*Tips: 移动端的是点击绿色字体查看成就描述, 而PC端的是鼠标放在绿色字体上</p>
        </div>
        <div class="section">
          <h1>与我联系</h1>
          <div class="contact-me">
            <a target="_blank" href="https://github.com/K05A4B">
              <i class="bi bi-github"></i>
            </a>
            <a target="_blank" href="https://space.bilibili.com/2036966108">
              <img :src="BilibiliIcon" alt="" />
            </a>

            <a href="mailto:k05a4b@outlook.com">
              <i class="bi bi-mailbox"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.root {
  padding: 16px !important;
  max-width: 1200px;
  margin: 0 auto;
}

.root > div {
  opacity: 0;
}

.root > div[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.top {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.bottom {
  display: flex;
  align-items: start;
}

.bottom > div {
  opacity: 0;
}

.bottom[data-animate] > div {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards 200ms;
}

.title {
  color: #dde6ed;
  font-size: var(--title-std-size);
}

.close {
  color: #dde6ed;
  background: transparent;
  border: none;
  font-size: 1cm;
  cursor: pointer;
  align-items: flex-start
}

.content {
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  background-color: #2f3f56;
  width: 100%;
  color: #dde6ed;
}

.section:not(:last-child) {
  border-bottom: 3px dashed #364458;
}

.section p {
  margin-left: 8px;
}

.section {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
}

.section.row {
  flex-direction: row;
}

.contact-me {
  display: flex;
}

.contact-me a {
  font-size: 1cm;
  color: #dde6ed;
  margin-left: 16px;
}

.contact-me a > img {
  width: 1cm;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
