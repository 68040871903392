<script setup>
import friendLinksText from "../assets/FRIEND_LINKS.svg";
import { defineProps, ref, onMounted } from "vue";
import links from "./links";
defineProps({ vcId: String });

const root = ref();

function playAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  var index = 0;

  var interval = setInterval(() => {
    if (index >= elements.length) {
      clearInterval(interval);
      return;
    }

    elements[index].setAttribute("data-animate", "true");
    index++;
  }, 450);
}

function stopAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("data-animate");
  }
}

function initObserver() {
  var observer = new IntersectionObserver((elem) => {
    if (elem[0].isIntersecting) {
      playAnimate();
    } else {
      stopAnimate();
    }
  });

  observer.observe(root.value);
}

onMounted(() => {
  initObserver();
});
</script>

<template>
  <div ref="root" class="root">
    <div class="top">
      <h1 class="title">友情链接</h1>
      <button class="close" :data-hidden="vcId">×</button>
    </div>
    <div class="bottom">
      <img :src="friendLinksText" alt="" />
      <div class="content">
        <a
          target="_blank"
          :href="elem.link"
          :key="elem"
          v-for="elem in links"
          class="section row frilnk-card"
        >
          <div class="info">
            <h1>{{ elem.name }}</h1>
            <p>{{ elem.description }}</p>
          </div>
          <div class="avatar">
            <img :src="elem.avatar" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.root {
  padding: 16px !important;
  max-width: 1200px;
  margin: 0 auto;
}

.root > div {
  opacity: 0;
}

.root > div[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.top {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.bottom {
  display: flex;
  align-items: start;
}

.bottom > div {
  opacity: 0;
}

.bottom[data-animate] > div {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards 200ms;
}

.title {
  color: #fff;
  font-size: var(--title-std-size);
}

.close {
  color: #fff;
  background: transparent;
  border: none;
  font-size: 1cm;
  cursor: pointer;
  align-items: flex-start;
}

.content {
  padding: 4px;
  margin: 8px;
  border-radius: 8px;
  background-color: #ff8a67;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.section:not(:last-child) {
  border-bottom: 3px dashed #ff8a67;
}

.section p {
  margin-left: 8px;
}

.section {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
}

.section.row {
  flex-direction: row;
}

.frilnk-card {
  width: 400px;
  max-width: 100%;
  max-height: 150px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  padding: 0;
  background-color: #ff9677;
  box-shadow: 0 0 20px #ff7b54e3;
  border: 3px solid #ffa286;
  margin: 8px;
  color: #fff;
  text-decoration: none;
}

.info {
  flex: 2;
  padding: 8px;
  box-sizing: border-box;
}

.avatar {
  max-width: 150px;
  flex: 1;
}

.avatar img {
  max-height: 100%;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 510px) { 
  .avatar {
    display: none;
  }

  .frilnk-card {
    display: block;
    width: 100%;
  }
}
</style>
