<script setup>
import notesText from "../assets/NOTES.svg";
import { defineProps, ref, onMounted } from "vue";
import fetchRss from "./fetchRss";
defineProps({ vcId: String });

const root = ref();
const list = ref([
  {
    title: "正在加载数据...",
    description:
      "正在加载数据...",
    link: "",
    pubDate: "",
  },
]);

function playAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  var index = 0;

  var interval = setInterval(() => {
    if (index >= elements.length) {
      clearInterval(interval);
      return;
    }

    elements[index].setAttribute("data-animate", "true");
    index++;
  }, 450);
}

function stopAnimate() {
  var rootEl = root.value;
  var elements = rootEl.querySelectorAll(".top,.bottom");
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("data-animate");
  }
}

function initObserver() {
  var observer = new IntersectionObserver((elem) => {
    if (elem[0].isIntersecting) {
      playAnimate();
      loadPosts();
    } else {
      stopAnimate();
    }
  });

  observer.observe(root.value);
}

function loadPosts() {
  fetchRss()
    .then((res) => {
      if (res.length <= 0) {
        list.value.title = "目前还没有数据 ≡(▔﹏▔)≡";
        list.value.description = "我目前没有写下任何一篇笔记";
        list.value.link = "https://notes.kzhik.cn";
        return;
      }
      list.value = res;
    })
    .catch((err) => {
      list.value = [
        {
          title: "获取数据失败!",
          description:
            "获取文章信息失败! 您可以直接访问http://notes.kzhik.cn来访问我的笔记\n错误信息: " + err,
          link: "http://notes.kzhik.cn",
          pubDate: "",
        },
      ];
    });
}

onMounted(() => {
  initObserver();
});
</script>

<template>
  <div ref="root" class="root">
    <div class="top">
      <h1 class="title">我的笔记</h1>
      <button class="close" :data-hidden="vcId">×</button>
    </div>
    <div class="bottom">
      <img :src="notesText" alt="" />
      <div class="content">
        <p>笔记页面与主页是分离的<del>(因为自己写太麻烦了, 而且还需要学习Typecho主题制作)</del>😶‍🌫️</p>
        <p>此处只会展示最新的10篇笔记🎉</p>
        <div :key="elem" v-for="elem in list" class="section note">
          <a :href="elem.link">
            <div class="note-top">
              <h1>{{ elem.title }}</h1>
              <p>{{ elem.description }}</p>
            </div>
            <div class="note-bottom">
              <p>发布于: {{ elem.pubDate }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.root {
  padding: 16px !important;
  max-width: 1200px;
  margin: 0 auto;
}

.root > div {
  opacity: 0;
}

.root > div[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.top {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.bottom {
  display: flex;
  align-items: start;
}

.bottom > div {
  opacity: 0;
}

.bottom[data-animate] > div {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards 200ms;
}

.title {
  color: #fff;
  font-size: var(--title-std-size);
}

.close {
  color: #fff;
  background: transparent;
  border: none;
  font-size: 1cm;
  cursor: pointer;
  align-items: flex-start;
}

.content {
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  background-color: #0dbc8a;
  width: 100%;
  color: #fff;
}

.section:not(:last-child) {
  border-bottom: 3px dashed #17d69f;
}

.section p {
  margin-left: 8px;
}

.section {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
}

.section.row {
  flex-direction: row;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.note {
  padding: 8px;
}

.note-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.note a {
  color: inherit;
  text-decoration: none;
}

.note .read-more {
  color: #b2fffc;
  text-decoration: none;
  margin-bottom: 3px;
  border-bottom: 2px solid #b2fffc;
}

.note p {
  text-indent: 0;
  margin: 8px 0;
}
</style>
