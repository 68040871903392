<script setup>
import { defineProps, ref } from 'vue';
defineProps(["target", "achievement", "description"])

const desc = ref();
const enabled = ref(false);

function show(ev) {
  var descEl = desc.value;

  if (ev.type == "click") {
    enabled.value = true;
    descEl.style.left = 1 + "em";
    descEl.style.top = 1 + "em";
  } else {
    descEl.style.left = ev.offsetX + "px";
    descEl.style.top = ev.offsetY + "px";
    enabled.value = true;
  }
}

function hidden() {
  enabled.value = false;
}
</script>

<template>
  <div class="achievement">
    <span class="blue">{{ target }}</span>
    <span> 获得成就 </span>
    <span class="green pointer" @click.stop="show" @mouseenter="show" @mouseout="hidden">{{ achievement }}</span>
    <span ref="desc" :data-enabled="enabled" class="description">
      成就描述:
      <br/>
      <span class="red">{{ description }}</span>
    </span>
  </div>
</template>

<style scoped>
.achievement {
  position: relative;
  font-size: 18px;
}

.pointer {
  cursor: pointer;
}

.green {
  color: #19e981;
}

.blue {
  color: #00ffff;
}

.red {
  color: rgb(254, 124, 124);
}

.description {
  position: absolute;
  opacity: 0;
  border: 1px solid #364458;
  border-radius: 5px;
  background-color: #2b3c54;
  padding: 4px;
  display: none;
  box-shadow: 0 0 20px #2d2d2d29;
  margin-top: 1em;
  min-width: 100px;
  z-index: 99;
}

.description[data-enabled="true"] {
  opacity: 1;
  display: block;
}
</style>
