import axios from "axios";

const FEED = "https://notes.kzhik.cn/feed/";

export default async function fetchRss() {
  var data = "";
  var result = []

  await axios.get(FEED)
  .then((res) => {
    data = res.data;
  })

  var rssParser = new DOMParser();
  var content = rssParser.parseFromString(data, "application/xml");
  var items = content.querySelectorAll("channel > item");
  
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    var title = item.querySelector("title").textContent;
    var link = item.querySelector("link").textContent;
    var description = item.querySelector("description").textContent;
    var pubDate = item.querySelector("pubDate").textContent;
    result.push({title, link, description, pubDate});
  }

  return result
}