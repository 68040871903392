<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  title: String,
  description: String,
  hoverColor: String,
  contentBg: String,
  vcId: String,
  hidden: Boolean
});

const STATE_ENABLED = "data-enabled";
const CARD = ref();
const CONTENT = ref()
const OUTER = ref();

const CARD_STYLE = {
  "--hover-color": props.hoverColor,
  "--content-bg": props.contentBg
}


function parseStyle(style) {
  let result = "";

  for (let key in style) {
    const value = style[key];
    result += `${key}:${value};`;
  }

  return result;
}

function hiddenContent() {
  var card = CARD.value;
  var content = CONTENT.value;
  content.removeAttribute("data-animate");
  card.removeAttribute(STATE_ENABLED);
}

function handleCardClick(ev) {
  var card = CARD.value;
  if (!card.hasAttribute(STATE_ENABLED)) {
    card.setAttribute(STATE_ENABLED, "true");
    setTimeout(() => {
      var content = CONTENT.value;
      content.setAttribute("data-animate", "");
    }, 600)

  } else {
    const vcId = ev.target.getAttribute("data-hidden");
    if (vcId == "" || vcId == null) {
      return;
    }

    if (vcId == props.vcId) {
      hiddenContent();
    }
  }
}

</script>

<template>
  <div v-if="!hidden" :style="parseStyle(CARD_STYLE)" @click="handleCardClick" ref="CARD" class="viewCard">
    <div ref="OUTER" class="cardOuter">
      <slot name="icon"></slot>
      <div class="text">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
      </div>
    </div>
    <div ref="CONTENT" class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped>
.viewCard {
  display: inline-block;
  cursor: pointer;
}

.viewCard .content {
  position: fixed;
  left: -105vw;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: 800ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--content-bg);
  cursor: default;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  z-index: 99;
}

.viewCard[data-enabled]>.content {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  left: 0;
}

.viewCard[data-enabled]>.cardOuter {
  opacity: 0;
  border: 0;
}

.viewCard * {
  margin: 0;
  padding: 0;
}

.text * {
  transition: 800ms cubic-bezier(0.15, 0.71, 0.33, 0.98);
}

.text h1 {
  font-size: 1.5rem;
  color: #444;
  text-align: center;
  font-weight: normal;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.text p {
  padding-top: 4px;
}

.cardOuter {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  background-color: rgba(227, 227, 227, 0.3);
  border: 1px solid #eee;
  backdrop-filter: blur(15px);
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  transition: 0.8s cubic-bezier(0.15, 0.71, 0.33, 0.98);
}

.viewCard:hover>.cardOuter .text * {
  transform: scale(1.1);
  color: var(--hover-color);
}

.viewCard:hover>.cardOuter> :first-child {
  color: var(--hover-color);
  transform: rotateZ(-15deg);
}

.viewCard:hover>.cardOuter {
  transform: scale(1.1);
}

.cardOuter> :first-child {
  font-size: 6rem;
  color: #333;
  transition: .3s ease-in-out;
}
</style>