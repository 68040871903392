import AboutWebsite from "../Contents/AboutWebsite.vue";
import AboutMe from "../Contents/AboutMe/AboutMe.vue";
import ProjectPage from "../Contents/ProjectPage.vue";
import MyNotes from "../Contents/MyNotes/MyNotes.vue";
import FriendLinks from "../Contents/FriendLinks/FriendLinks.vue";

export default [
  {
    vcId: "aboutWebsite",
    icon: "globe2",
    title: "关于此网站",
    description: "了解关于此网站的信息",
    hoverColor: "#1e90ff",
    contentBg: "#1e90ff",
    content: {
      name: AboutWebsite,
      value: "",
      attributes: {
        vcId: "aboutWebsite",
      },
    },
  },
  {
    vcId: "aboutMe",
    icon: "person",
    title: "关于",
    description: "了解关于我的信息",
    hoverColor: "#27374D",
    contentBg: "#27374D",
    content: {
      name: AboutMe,
      attributes: {
        vcId: "aboutMe",
      },
    },
  },
  {
    vcId: "project",
    icon: "tools",
    title: "作品",
    description: "一些简单的小软件/程序",
    hoverColor: "#EC7272",
    contentBg: "#EC7272",
    content: {
      name: ProjectPage,
      attributes: {
        vcId: "project",
      },
    },
  },
  {
    vcId: "myNotes",
    icon: "book",
    title: "我的笔记",
    description: "记录一些奇奇怪怪的东西",
    hoverColor: "#00AD7C",
    contentBg: "#00AD7C",
    content: {
      name: MyNotes,
      attributes: {
        vcId: "myNotes",
      },
    },
  },
  {
    hidden: true,
    vcId: "music",
    icon: "music-note-beamed",
    title: "MUSIC",
    description: "来点音乐吗? (电子音乐)",
    hoverColor: "#ff5e57",
    contentBg: "#ff5e57",
    content: {},
  },
  {
    vcId: "friendLink",
    icon: "link",
    title: "友情链接",
    description: "此卡片没有描述 :)",
    hoverColor: "#FF7B54",
    contentBg: "#FF7B54",
    content: {
      name: FriendLinks,
      attributes: {
        vcId: "friendLink",
      },
    },
  },
];
