<script setup>
import { onMounted, ref } from "vue";
import HsBackground from "./HsBackground/HsBackground.vue"
import ViewCard from "../ViewCard.vue";
import HomeSection from "../HomeSection/HomeSection.vue"

var dynamicTitles = ref();

var loadEnded = ref(false);

function handleDynamicTitle() {
  var titles = dynamicTitles.value;
  var childNodes = titles.childNodes;
  var len = childNodes.length;
  var index = 0;
  var delta = 12;

  setInterval(() => {
    if (index >= len) {
      index = 0;
    }

    titles.style.transform = `translateY(-${delta * index}rem)`;
    index++;

  }, 3000)
}

onMounted(() => {
  handleDynamicTitle();
});

window.addEventListener("load", function() {
  loadEnded.value = true;
})
</script>

<template>
  <div :id="$style.heroSection">
    <HsBackground :play-animate="loadEnded" />
    <div :class="$style.foreground" :data-animate="loadEnded">
      <h1 :class="$style.dynamicTitle">
        <div ref="dynamicTitles" class="titles">
          <p>欢迎!</p>
        </div>
      </h1>
      <p :class="$style.description">kzhik's website</p>
      <p :class="$style.copyright">Copyright kzhik.cn All rights reserved</p>
      <div :class="$style.cards">
        <ViewCard title="目录" content-bg="#fff" vc-id="contents" hover-color="#f04c68" description="点我展开目录">
          <template v-slot:icon>
            <i class="bi bi-card-list"></i>
          </template>
          <template v-slot:content>
            <HomeSection/>
          </template>
        </ViewCard>
      </div>
    </div>
    <h1 :class="$style.loadingPrompt">正在加载...</h1>
  </div>
</template>

<style module>
#heroSection {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#heroSection>p:not(.dynamicTitle p) {
  margin-left: 18px;
  color: #333;
  font-size: 26px;
}

.loadingPrompt {
  color: dodgerblue;
  position: fixed;
  bottom: 16px;
  text-align: center;
  width: 100vw;
}

.foreground[data-animate=true] ~ .loadingPrompt {
  animation: fadeOut 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.foreground {
  padding: 16px 32px;
}

.foreground > *:not(.cards) {
  opacity: 0;
}

.foreground[data-animate=true] > *:not(.cards) {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
}

.foreground[data-animate=true] > .description {
  animation-delay: .6s;
  text-indent: 2rem;
}

.foreground[data-animate=true] > .copyright {
  animation-delay: .7s;
  text-indent: 2rem;
}

.cards > div > :first-child{
  opacity: 0;
}

.foreground[data-animate=true] > .cards > div > :first-child {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
  animation-delay: .8s;
}

.dynamicTitle {
  margin: 8px;
  font-size: var(--hero-section-title-size);
  color: #333;
  overflow: hidden;
  margin-left: 16px;
  position: relative;
}

.foreground[data-animate=true] .dynamicTitle {
  animation-delay: .5s;
}

.dynamicTitle::after {
  content: '';
  background-color: dodgerblue;
  bottom: 0px;
  height: 3px;
  width: 20%;
  position: absolute;
}

.dynamicTitle * {
  margin: 0;
}

.dynamicTitle>.titles {
  transform: translateY(0rem);
  transition: 800ms cubic-bezier(0.61, 1, 0.88, 1);
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);

  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(100px);

  }
}
</style>