<script setup>
import {defineProps, onMounted, ref} from "vue";
import AboutWebsite from "./assets/ABOUT_WEBSITE.svg"
const props = defineProps(["vcId"]);
const ROOT = ref();
const CONTENT = ref();

onMounted(() => {
  var observer = new IntersectionObserver((arr) => {
    for (var i = 0; i < arr.length; i++) {
      var elem = arr[i];
      if (elem.isIntersecting) {
        elem.target.setAttribute("data-animate", "true");
      } else {
        elem.target.removeAttribute("data-animate");
      }
    }
  })

  observer.observe(ROOT.value)
  observer.observe(CONTENT.value)
})

</script>

<template>
  <div ref="ROOT" :class="$style.root">
    <button @click="handleHidden" :class="$style.close" :data-hidden="props.vcId">×</button>
    <h1 :class="$style.title">关于此网站</h1>
    <div :class="$style.box">
      <img :src="AboutWebsite" alt="ABOUT WEBSITE">
      <div ref="CONTENT" :class="$style.content">
        <h1>嗨,</h1>
        <p>这是我的个人网站! 所以说为什么我会创建这个网站呢? 让我来给你娓娓道来...</p>
        <p>就在不久前, 我买了一台服务器 以及 kzhik.cn 这个域名, 但是我不知道它能用来做什么...</p>
        <p>于是，灵感迸发，我决定创建这个网站。这里成为了我分享各种有趣想法、项目和经验的地方。</p>
        <p>无论你是偶然发现这里，还是有意而来，我都希望你在这个小角落里找到一些有趣或有用的信息。谢谢你的光临！❤️</p>
        <p>如果你有任何想法、建议或只是想打个招呼，随时都可以联系我。让我们共同创造更多有趣的内容吧！🚀</p>
        <p>最后感谢 <a target="_blank" href="https://xihuannio.cn/">Yizuko</a> 提供的服务器简单使用指南 帮助我建设了我的第一个网站😁</p>
      </div>
    </div>
  </div>
</template>

<style module>
.root {
  padding: 16px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  opacity: 0;
}

.root hr {
  border: 2px solid rgb(100, 177, 255);
  margin-top: 8px;
}

.root[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
  animation-delay: .6s;
}

.close {
  color: #fff;
  background: transparent;
  border: none;
  font-size: 1cm;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 9;
  cursor: pointer;
}

.title {
  color: #deeeff;
  font-size: var(--title-std-size);
  line-height:  var(--title-std-size);
  padding-bottom: 8px;
}

.box {
  display: flex;
}

.box img {
  margin-right: 16px;
}

.content {
  background: #409dff;
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  max-width: 900px;
  color: #cee7ff;
  opacity: 0;
}

.content[data-animate] {
  animation: fadeUp 1.4s cubic-bezier(0.25, 0.79, 0.35, 1.09) forwards;
  animation-delay:  1s;
}

.content a {
  color: #eff7ff;
}

.content p {
  margin-top: 8px;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>