<template>
  <div id="hero-section-bg" ref="bgElem"></div>
</template>

<style>
#hero-section-bg {
  position: absolute;
  z-index: -1;
}
</style>

<script setup>
import { onMounted, onUpdated, ref, defineProps } from 'vue';
import * as THREE from "three";
import Planet from './planet';

const props = defineProps(["playAnimate"])

var bgElem = ref();

const VIEW_WIDTH = window.innerWidth;
const VIEW_HEIGHT = window.innerHeight;

var planet = new Planet(2.2, 0x1e90ff, 0x0962EA);


var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera();
var renderer = new THREE.WebGLRenderer({ antialias: true });

function initComponent() {
  camera.fov = 750;
  camera.aspect = VIEW_WIDTH / VIEW_HEIGHT;
  camera.near = 0.5;
  camera.position.z = 60;
  camera.updateProjectionMatrix();

  renderer.setSize(VIEW_WIDTH, VIEW_HEIGHT);
  renderer.setClearAlpha(0);
  renderer.setPixelRatio(window.devicePixelRatio);
  bgElem.value.appendChild(renderer.domElement);
}

function loadObject() {
  scene.add(planet.getObject());
}

function animateRender() {
  requestAnimationFrame(animateRender);
  planet.renderAnimate();
  renderer.render(scene, camera);
}

function handleViewerResize() {
  const VIEW_WIDTH = window.innerWidth;
  const VIEW_HEIGHT = window.innerHeight;

  camera.aspect = VIEW_WIDTH / VIEW_HEIGHT;
  camera.updateProjectionMatrix();

  renderer.setSize(VIEW_WIDTH, VIEW_HEIGHT);
  renderer.setPixelRatio(window.devicePixelRatio);

}

onMounted(async () => {
  initComponent();
  loadObject();
  animateRender();

  window.addEventListener("resize", handleViewerResize)
});

onUpdated(() => {
  if (props.playAnimate) {
    planet.start();
  }
})
</script>