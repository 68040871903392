import LizhishutongLogo from "../assets/lizhishutong.svg"

export default[
  {
    name: "挽歌歌吖",
    description: "你怎么知道他喜欢萝莉🤔",
    avatar: "https://q.qlogo.cn/headimg_dl?dst_uin=3419584895&spec=640&img_type=jpg",
    link: "https://xihuannio.cn"
  },
  {
    name: "荔枝疏通",
    description: "我们的寝室网站",
    avatar: LizhishutongLogo,
    link: "https://lizhi.xihuannio.cn/"
  }
]