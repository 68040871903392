import * as THREE from "three";
import { Tween } from "@tweenjs/tween.js";

function getPointsOnCircle(radius, count) {
  const delta = (Math.PI * 2) / count; // 均分一个圆
  const result = [];

  for (var i = 0; i < count; i++) {
    const x = radius * Math.cos(delta * i); // 获取 delta*i (角度) 与圆的半径相交的点的 x 的值
    const y = radius * Math.sin(delta * i); // 此处同上 只不过是计算 y 轴的值

    result.push(x, y, 0);
  }

  return new Float32Array(result);
}

function makeRing(size, pointsCount, materialParam) {
  var geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(getPointsOnCircle(size, pointsCount), 3)
  );

  var material = new THREE.PointsMaterial(
    Object.assign({ color: 0x4444444, size: 0.05 * size }, materialParam)
  );

  var ring = new THREE.Points(geometry, material);

  return { ring, geometry, material };
}

function makePlanet(radius, color) {
  var geometry = new THREE.IcosahedronGeometry(radius, 1);
  var material = new THREE.MeshBasicMaterial({ color, wireframe: true });
  var planet = new THREE.Mesh(geometry, material);

  return { planet, material, geometry };
}

class Plant {
  constructor(size = 1, planetColor = 0x1e90ff, ringColor = 0x4444444) {
    this._group = new THREE.Group();

    const PLANET_RADIUS = 6.6 * size;

    this.ring = makeRing(9 * size, 120, { color: ringColor });
    this.planet = makePlanet(PLANET_RADIUS, planetColor);

    this.ANIMATE_PARAMS = {
      ringRotationX: 0,
      ringRotationY: 0,

      planetRotationX: 0,
      planetRotationY: 0,

      groupPositionX: 0,
      groupScaleX: 0.3,
      groupScaleY: 0.3,
      groupScaleZ: 0.3,
    };

    this.ANIMATE_PARAMS_TO = {
      ringRotationX: Math.PI / 2,
      ringRotationY: Math.PI / 1.1,

      planetRotationX: Math.PI / 1.5,
      planetRotationY: Math.PI / 1.1,

      groupPositionX: 13,
      groupScaleX: 1,
      groupScaleY: 1,
      groupScaleZ: 1,
    };

    this.animationController = new Tween(this.ANIMATE_PARAMS);
    this._group.add(this.ring.ring, this.planet.planet);
    this.updateObject();
  }

  start() {
    this.animationController
      .easing((x) => {
        return 1 - Math.pow(1 - x, 3);
      })
      .onUpdate(() => {
        this.updateObject();
      })
      .stop()
      .to(this.ANIMATE_PARAMS_TO, 1000)
      .start();
  }

  // 更新物体状态
  updateObject() {
    var ring = this.ring.ring;
    var planet = this.planet.planet;
    var animateParams = this.ANIMATE_PARAMS;

    ring.rotation.x = animateParams.ringRotationX;
    ring.rotation.y = animateParams.ringRotationY;

    planet.rotation.x = animateParams.planetRotationX;
    planet.rotation.y = animateParams.planetRotationY;

    this._group.position.x = animateParams.groupPositionX;
    this._group.scale.set(
      animateParams.groupScaleX,
      animateParams.groupScaleY,
      animateParams.groupScaleZ
    );
  }

  // 需要将此函数放入动画循环内
  renderAnimate() {
    this.ring.ring.rotation.z += Math.PI / 800;
    this.planet.planet.rotation.z += Math.PI / 1600;
    this.animationController.update();
  }

  getObject() {
    return this._group;
  }
}

export default Plant;
